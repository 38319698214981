.HomeQr {
    height: 100vh;
    width: 100%;

}

#NavBar {
    position: relative;
    border: 1px solid transparent;
}

#NavBarFixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: var(--color1);
    z-index: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
}

#NavBar * {
    color: white;
}




.SectionContainer {
    position: relative;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
}

.SectionTitleContainer {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.SectionTitle {
    color: white;
    width: 100%;
    text-align: center;
    background: #0d121a5f;/*linear-gradient(to right, #0d121a00, #0d121a70, #0d121a70, #0d121a70, #0d121a00);*/
    font-size: 26px;
    font-weight: 600;
    padding: 1rem;
}

.SectionImage {
    width: 100%;
    aspect-ratio: 16/9;
    background: none;

}




@media screen and (min-width: 600px) {
    .SectionImage {
        border-radius: 1rem;
        margin: 2rem auto 0 auto;
        box-shadow: 0 0 5px #1017206b;
    }

    .SectionTitleContainer {
        top: 1rem;
    }
}