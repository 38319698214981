:root {
  --color1: #101720;

  --success: #3cbc84;
  --warning: rgba(248, 197, 103, 0.94);
  --danger: #d25858;



  font-size: 14px;

}



* {
  font-family: "Montserrat", sans-serif;
  color: black;
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;

}

html {
  scroll-behavior: smooth;
}


body {
  box-sizing: border-box;
  min-height: 100vh;
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.Cursor {
  cursor: pointer;
}

.mt2 {
  margin: 2rem;
}

.mt0-1 {
  margin: 0 1rem;
}

.mt15 {
  margin: 1.5rem;
}

.mt1-2 {
  margin: 1rem 2rem;
}

.mt2-2 {
  margin: 2rem 2rem;
}



.pd1 {
  padding: 1rem;
}

.pd1-2 {
  padding: 1rem 2rem;
}

.pd05-1 {
  padding: 0.5rem 1rem;
}

.pd05 {
  padding: 0.5rem;
}

.ft12 {
  font-size: 12px;
}

.ft14 {
  font-size: 14px;
}

.ft16 {
  font-size: 16px;
}

.ft18 {
  font-size: 18px;
}

.ft20 {
  font-size: 20px;
}

.ft22 {
  font-size: 22px;
}

.f242 {
  font-size: 24px;
}


.FlexWrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.FlexColumnWrap {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.FlexNoWrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.FlexColumnNoWrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.FlexWrapBetween {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}


.FlexNoWrapBetween {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.FlexWrapAround {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.FlexNoWrapAround {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.FlexReverseWrapAround {
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.FlexWrapStart {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.FlexWrapStart2 {
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.button {
  box-shadow: 0 3px 8px var(--color5);
}

.button:hover {
  opacity: 0.9;
}

.Shadow {
  box-shadow: 0 2px 8px var(--color9);
}

.Divider {
  height: 1.5px;
  background: linear-gradient(to right, white, var(--color10), white);
  margin: 1.5rem 0 1rem 0;
}



.AsyncMockup {
  width: 100%;
  aspect-ratio: 16/9;
  background: none;
}


@media screen and (min-width: 600px) {

  .AsyncMockup {
    border-radius: 1rem;
    margin: 0rem auto 0 auto;
    box-shadow: none;
  }
}



.moveToLeft {
  -webkit-animation: moveToLeft 0.7s ease ease-out;
  animation: moveToLeft 0.7s ease-out;
}

@-webkit-keyframes moveToLeft {
  from {
    -webkit-transform: translateX(95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes moveToLeft {
  from {
    -webkit-transform: translateX(95%);
    transform: translateX(95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
}




.moveToRight {
  display: block;
  -webkit-animation: moveToRight .8s ease ease-out;
  animation: moveToRight .8s ease-out;
}

@-webkit-keyframes moveToRight {
  from {
    -webkit-transform: translateX(-95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes moveToRight {
  from {
    -webkit-transform: translateX(-95%);
    transform: translateX(-95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
}



.shortMoveToLeft {
  -webkit-animation: shortMoveToLeft 0.4s ease ease-out;
  animation: shortMoveToLeft 0.4s ease-out;
}

@-webkit-keyframes shortMoveToLeft {
  from {
    -webkit-transform: translateX(15%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes shortMoveToLeft {
  from {
    -webkit-transform: translateX(15%);
    transform: translateX(15%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
}




.moveToTop {
  -webkit-animation: moveToTop 0.3s ease ease-out;
  animation: moveToTop 0.3s ease-out;
}

@-webkit-keyframes moveToTop {
  from {
    -webkit-transform: translateY(95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveToTop {
  from {
    -webkit-transform: translateY(95%);
    transform: translateY(95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }
}




.moveToBottom {
  -webkit-animation: moveToBottom 0.7s ease ease-out;
  animation: moveToBottom 0.7s ease-out;

}

@-webkit-keyframes moveToBottom {
  from {
    -webkit-transform: translateY(-200px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveToBottom {
  from {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }
}